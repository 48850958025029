export default [
    {
        name: 'Home',
        path: '/',
        component: require('@/pages/HomePage').default,
    },
    {
        name: 'Basic',
        path: '/basic',
        component: require('@/pages/BasicPage').default,
    },
    {
        name: 'raids',
        path: '/raids',
        component: require('@/pages/RaidsPage').default,
    },
    {
        name: 'ranking',
        path: '/ranking',
        component: require('@/pages/RankingPage').default,
    },
    {
        name: 'statistics',
        path: '/statistics',
        component: require('@/pages/statisticsPage').default,
    }
]