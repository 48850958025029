

<template>
  <div class="box">
    <app-bar class="block"></app-bar>
    <div class="content">
      <div>
        <div>
          <div>
            <img src="../assets/rw.png" alt="" />
            <p>LDC</p>
            <span>Volume</span>
            <div>{{ info.volume }}</div>
          </div>
          <div>
            <img src="../assets/td.png" alt="" />
            <p>Team</p>
            <span>Memebrs</span>
            <div>{{ info.memebrs }}</div>
          </div>
        </div>
        <div>
          <div>
            
            <div>Reference link</div>
            <p>{{ formatString2(url) }}</p>
          </div>
          <img v-if="Address != null" style="cursor: pointer;" @click="copyTextToClipboard(url)" src="../assets/fz2.png" alt="" />
        </div>
      </div>

      <img class="tu1" src="../assets/jj1.png" alt="" />
      <img class="tu2" src="../assets/jj2.png" alt="" />
    </div>
    <div class="Rules">
      <div class="tu_pc">
        <img class="tu1" src="../assets/door.png" alt="" />
        <img class="tu2" src="../assets/tx.png" alt="" />
      </div>
      <div>
        <h2>Rules <img src="../assets/stars.png" alt="" /></h2>
        <p>
          <img src="../assets/star.png" alt="" />Invite 20 people to purchase
          LDc to unlock team benefits.
        </p>
        <p>
          <img src="../assets/star.png" alt="" />The private placement period
          ends (30 days) or the quota is sold outearly, and the activity
          automatically ends.
        </p>
        <p>
          <img src="../assets/star.png" alt="" />Any member who invites 20
          people can automatically form a new team.
        </p>
        <p>
          <img src="../assets/star.png" alt="" />The top 50% of the ranking list
          receives a share of 10% LDC reward. Score=LDC quantity*60%+ number of
          team members*40%.
        </p>
      </div>
      <div class="tu_m">
        <img class="tu3" src="../assets/door.png" alt="" />
        <img class="tu4" src="../assets/tx.png" alt="" />
      </div>
    </div>
    <div class="record">
      <div>
        <span @click="change = true" :class="{'dis':change == true}">Ranking</span>
        <span @click="change = false" :class="{'dis':change == false}">Members</span>
      </div>
      <div class="Ranking_head" v-if="change">
        <div>
          <span>Ranking</span>
          <span>Team</span>
        </div>
        <div>
          <span>Members</span>
          <span>Possesses (LDC)</span>
          <span>Score</span>
        </div>
      </div>
      <div class="Members_head" v-else>
        <span>Address</span>
        <span>Received(LDC)</span>
      </div>
      <div class="Ranking" v-if="change">
        <div v-for="item in teamRanking" :key="item">
          <div>
            <span>{{item.ranking}}</span>
            <span>{{formatString(item.team)}}</span>
          </div>
          <div>
            <span>{{item.members}}</span>
            <span>{{ item.possesses_ldc }}</span>
            <span>{{item.score}}</span>
          </div>
        </div>
        
      </div>
      <div class="Members" v-else>
        <div v-for="(item,index) in membersRanking" :key="index">
          <span>{{ formatString(item.sol_address) }}</span>
          <span>{{item.possesses_ldc}}</span>
        </div>
      </div>
      <div class="fys">
        <div class="fy">
        <el-pagination 
      v-if="change" 
      style="width:auto; margin: 0 auto; margin-top: .625rem;" 
      layout="prev, pager, next" 
      :total="teamRanking_total" 
      :current-page="Pagination.page"
        :page-size="Pagination.pageSize"
        class="mt-4"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"/>
        <el-pagination
         v-else 
         style="width: auto; margin: 0 auto; margin-top: .625rem;" 
         layout="prev, pager, next" 
         :total="membersRanking_total" 
      :current-page="Pagination2.page"
        :page-size="Pagination2.pageSize"
        class="mt-4"
        @current-change="handleCurrentChange2"
        @size-change="handleSizeChange2"
         />
      </div>
      </div>
    </div>
    
  </div>
</template>
<script setup>
import { ref,onMounted,watch,reactive } from "vue";
import AppBar from "../components/AppBar.vue";
import { get,post } from "../util/axios";
import { useWallet } from "solana-wallets-vue";
import { ElMessage,ElNotification } from "element-plus";
const { publicKey} = useWallet();
let Address = ref(publicKey.value == null ?publicKey.value :publicKey.value.toString())
const currentURL = ref( window.location.origin);
  // {{currentURL}}/?address={{Address}}
  let url = `${currentURL.value}/#/?address=${Address.value}`
onMounted(() => {
  if(Address.value == null) return
  trans_info()
  team_ranking()
  members_ranking()
});
watch(
  () => publicKey.value,
  (newValue, oldValue) => {
    if (newValue != null) {
      Address.value = newValue.toString()
      trans_info()
      team_ranking()
      members_ranking()
      url = `${currentURL.value}/#/?address=${Address.value}`
    }
  }
);
let info = ref({
  volume:0.00,
  memebrs:0.00
})
const trans_info = ()=>{
  let data = { 
    sol_address:Address.value
  }
  post("/api/app/trans_info", data)
    .then((res) => {
      info.value = res.data
        // let url = res.data
        // window.location.href = url
    })
    .catch((e) => {
      console.error(e);
    });
}
let teamRanking = ref([])
let teamRanking_total = ref(0)
let Pagination = reactive({
  page:1,
  pageSize:5,
})
const team_ranking = ()=>{
  let data = {
     page:Pagination.page,
    pageSize: Pagination.pageSize
}
  post("/api/app/team_ranking", data)
    .then((res) => {
      teamRanking.value =moveObjectWithBEqualTo6(res.data.List)
      teamRanking_total.value = res.data.Total
      // info.value = res.data
        // let url = res.data
        // window.location.href = url
    })
    .catch((e) => {
      console.error(e);
    });
}
const handleSizeChange = (e)=>{
Pagination.page = e
  team_ranking()
}
const handleCurrentChange = (e)=>{
  Pagination.page = e
  team_ranking()

}
let membersRanking = ref([])
let membersRanking_total = ref(0)
let Pagination2 = reactive({
  page:1,
  pageSize:5,
})
const members_ranking = ()=>{
  let data = {
     page:Pagination2.page,
    pageSize: Pagination2.pageSize,
    sol_address:Address.value
}
  post("/api/app/members_ranking", data)
    .then((res) => {
      membersRanking.value = res.data.List
      membersRanking_total.value = res.data.Total
    })
    .catch((e) => {
      console.error(e);
    });
}
const handleSizeChange2 = (e)=>{
Pagination.page = e
members_ranking()
}
const handleCurrentChange2 = (e)=>{
  Pagination.page = e
  members_ranking()

}
function moveObjectWithBEqualTo6(arr) {
  const indexOfBEqualTo6 = arr.findIndex(obj => obj.sol_address === Address.value);
  
  if (indexOfBEqualTo6 !== -1) {
    const obj = arr.splice(indexOfBEqualTo6, 1)[0];
    arr.unshift(obj);
  }
  
  return arr;
}
function formatString(str) {
  const prefix = str.slice(0, 6);
  const suffix = str.slice(-5);
  return `${prefix}...${suffix}`;
}
function formatString2(str) {
  const prefix = str.slice(0, 30);
  const suffix = str.slice(-5);
  return `${prefix}...${suffix}`;
}
function copyTextToClipboard(text) {
  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Ensure the textarea is not visible
  textarea.style.position = "fixed";
  textarea.style.top = "-999999px";

  document.body.appendChild(textarea);
  textarea.select();

  // Execute the copy command
  document.execCommand("copy");

  // Clean up
  document.body.removeChild(textarea);
  ElNotification({
    title: 'Success',
    message: 'Copy successful.',
  })
}
let change = ref(true)
</script>
<style lang="less" scoped>
.box {
  padding-top: 10%;
  font-family:AbrilFatface;
  @media (max-width: 800px) {
    background: #f2ecde !important;
  }
  
  @media (max-width: 800px) {
    padding-top: 30%;
  }
}
.content {
  padding: 0 8%;
  // background: #f5e2b6;
  @media (max-width: 800px) {
    padding: 0 4%;
  }
  position: relative;
  .tu1 {
    width: 3rem;
    height: 3.4625rem;
    position: absolute;
    left: 0;
    bottom: 0.5rem;
    @media (max-width: 800px) {
      width: 40px;
      height: 40px;
      bottom: 80px;
    }
  }
  .tu2 {
    width: 4.1375rem;
    height: 4.425rem;
    position: absolute;
    right: 0;
    top: 0;
    @media (max-width: 800px) {
      width: 60px;
      height: 60px;
    }
  }
  > div:nth-child(1) {
    width: 100%;
    border-radius: 0.3rem;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(0.4625rem);
    padding: 0.75rem;
    @media (max-width: 800px) {
      padding:10px;
    }
    > div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > div {
        width: 49%;
        border-radius: 0.3rem;
        border: 0.0375rem solid #000;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0.375rem;
        box-sizing: border-box;
        img {
          width: 1.625rem;
          height: 1.625rem;
          flex-shrink: 0;
          border-radius: 50%;
          @media (max-width: 800px) {
            width: 30px;
            height: 30px;
          }
        }
        p {
          color: #000;
          font-size: 0.4rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          padding: 0.125rem 0 0.075rem 0;
        }
        span {
          color: rgba(0, 0, 0, 0.4);
          font-size: 0.25rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          @media (max-width: 800px) {
            font-size: 10px;
          }
        }
        div {
          color: #ff7e00;
          text-align: right;
          font-size: 0.625rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-top: 0.25rem;
        }
      }
    }
    > div:nth-child(2) {
      width: 100%;
      height: 1.5rem;
      border-radius: 0.3rem;
      border: 0.0375rem solid #000;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      // overflow: hidden;
      margin-top: 0.375rem;
      > div {
        display: flex;
        align-items: center;
        height: 100%;
        > div {
          height: 100%;
          background: #000;
          color: #fff;
          font-size: 0.4rem;
          font-weight: 400;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 0.25rem;
          border-bottom-left-radius: .1875rem;
          border-top-left-radius:.1875rem;
          @media (max-width: 800px) {
              font-size: 12px;
            }
        }
        p {
          color: #ff7e00;
          font-size: 0.4rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          margin-left: 0.5rem;
          @media (max-width: 800px) {
              font-size: 10px;
            }
        }
      }
      img {
        margin-right: 0.75rem;
        @media (max-width: 800px) {
             width: 20px;
             height: 20px;
            }
      }
    }
  }
}
.Rules {
  width: 100%;
  height: 100vh;
  padding: 0 8%;
  background-color: #f2ecde;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 800px) {
      height: 85vh;
      padding: 0 4%;
      flex-direction: column;
      justify-content: center;
    align-items: flex-start;
  }
  .tu_pc{
    margin-right: 1.25rem;
    position: relative;
    @media (max-width: 800px) {
      display: none;
    }
    .tu1 {
      width: 5rem;
      height: 5.875rem;
    }
    .tu2 {
      width: 5rem;
      height: 5rem;
      max-width: 5rem;
      position: absolute;
      right: -70%;
      bottom: 10%;
    }
  }
  .tu_m{
    display: none;
  }
  @media (max-width: 800px) {
    .tu_m{
    // margin-right: 1.25rem;
    position: relative;
    display: block;
    .tu3{
      width: 120px;
    }
    .tu4 {
      width: 200px;
      height: 200px;
      max-width: 200px;
      position: absolute;
      right: -70%;
      bottom: 0%;
    }
  }
    }
 
  > div:nth-child(2) {
    @media (max-width: 800px) {
      margin-bottom: 30px;
    }
    h2 {
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
      text-shadow: 0.0375rem 0.0375rem 0px #ff7e00;
      display: flex;
      align-items: center;
      margin-bottom: 0.375rem;
      img {
        width: 3.675rem;
        height: 0.675rem;
        flex-shrink: 0;
        margin-left: 0.1875rem;
      }
    }
    p {
      display: flex;
      margin-bottom: 0.25rem;
      img {
        width: 0.625rem;
        height: 0.625rem;
        flex-shrink: 0;
        margin-right: 0.125rem;
      }
      color: #000;
      font-size: 0.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  
}
.record {
  width: 100%;
  height: 100vh;
  padding: 0 8%;
  background-color: #f2ecde;
  @media (max-width: 800px) {
    height: 80%;
  padding: 0 4%;
        }
  > div:nth-child(1) {
    margin-bottom: .375rem;
    span {
      color: rgba(0, 0, 0, 0.4);
      font-size: .875rem;
      font-weight: 600;
      text-transform: capitalize;
      margin-right: 2.5rem;
      cursor: pointer;
      @media (max-width: 800px) {
        font-size: 15px;
        }
    }
    .dis{
      color: #000;
    }
  }
  .Ranking_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .25rem;
    > div:nth-child(1) {
      > span:nth-child(1) {
        width: 30%;
      }
      > span:nth-child(2) {
        width: 70%;
      }
    }
    >div:nth-child(1){
      width: 40%;
    }
    > div {
      width: 60%;
      display: flex;
      align-items: center;
      @media (max-width: 800px) {
        width: 50%;
        }
      span {
        display: inline-block;
        width: 33%;
        color: rgba(0, 0, 0, 0.4);
        font-size: .4rem;
        font-style: normal;
        font-weight: 600;
        @media (max-width: 800px) {
          font-size: 10px;
        }
      }
    }
  }
  .Members_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .25rem;
    span {
      display: inline-block;
      color: rgba(0, 0, 0, 0.4);
      font-size: .4rem;
      font-weight: 600;
      
    }
    > span:nth-child(1) {
      width: 70%;
    }
    > span:nth-child(2) {
      width: 30%;
    }
  }
  .Members {
    > div {
      display: flex;
    align-items: center;
      span {
        display: inline-block;
        color: #000;
        font-size: .5rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        @media (max-width: 800px) {
        font-size: 10px;
        }
      }
      > span:nth-child(1) {
        width: 70%;
      }
    }
  }
  .Ranking {
    > div:nth-child(1) {
      // width: 40%;
      span {
        color: #ff7e00 !important;
      }
    }
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: .125rem;
      > div:nth-child(1) {
        width: 40%;
        > span:nth-child(1) {
          width: 30%;
        }
        > span:nth-child(2) {
          width: 70%;
        }
      }
      > div {
        width: 60%;
        display: flex;
        align-items: center;
        @media (max-width: 800px) {
        width: 50%;
        }
        span {
          display: inline-block;
          width: 50%;
          color: #000;
          font-size: .5rem;
          font-style: normal;
          font-weight: 600;
          @media (max-width: 800px) {
          font-size: 10px;
          }
        }
      }
    }
  }
}
:deep(.el-pager){
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .is-active{
    color: #FF7E00 !important;
    border: 1px solid #FF7E00;
  }
}
:deep(.el-pager li:hover){
  color: #FF7E00 !important;
}
:deep(.el-pagination){
  display: flex;
  justify-content: space-between;
  .btn-prev{
      margin-right: 10px;
    }
    .btn-next{
      margin-left: 10px;
    }
    .el-pager{
      // width: 60%;
    li{
      margin: 0 5px;
    } 
    }
}
.fys{
  display: flex;
  justify-content: center;
  align-items:center;
}
.fy{
  display: inline-block;
  margin: 0 auto;
}

@media (max-width: 800px) {
   :deep(.el-pagination){
    width: 100% !important;
    display: flex;
    justify-content: space-between;
    .btn-prev{
      width: 25px;
      height: 25px !important;
      min-width: 25px;
      margin-right: 10px;
    }
    .btn-next{
      width: 25px;
      height: 25px !important;
      min-width: 25px;
      margin-left: 10px;
    }
    .el-pager{
      // width: 60%;
    li{
      width: 25px;
      height: 25px !important;
      min-width: 25px;
      margin: 0 5px;
    } 
    }
   }
  }
</style>