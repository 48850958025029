<template>
  <div class="box">
    <div>
      <div>
        SOL address : <input type="text" v-model.trim="address" />
        <el-button type="primary" @click="trans_info">Primary</el-button>
      </div>
      <div>
        <div>
          Total Ldc: <span>{{ TotalLdc }}</span>
        </div>
        <div>
          Total Person: <span>{{ TotalPerson }}</span>
        </div>
      </div>
    </div>
    <div id="statistics">
      <el-tree
        v-if="nodata"
        style="max-width: 600px"
        :data="ac"
        :props="defaultProps"
      />
    </div>
  </div>
</template>

<script setup>
import * as echarts from "echarts";
import { ref, onMounted, watch, reactive } from "vue";
import { get, post } from "../util/axios";
let address = ref("");
let TotalLdc = ref(0);
let TotalPerson = ref(0);
onMounted(() => {
  // trans_info()
});

const data1 = [
  {
    label: 'Level one 1',
    children: [
      {
        label: 'Level two 1-1',
        children: [
          {
            label: 'Level three 1-1-1',
          },
        ],
      },
    ],
  },
  {
    label: 'Level one 2',
    children: [
      {
        label: 'Level two 2-1',
        children: [
          {
            label: 'Level three 2-1-1',
          },
        ],
      },
      {
        label: 'Level two 2-2',
        children: [
          {
            label: 'Level three 2-2-1',
          },
        ],
      },
    ],
  },
  {
    label: 'Level one 3',
    children: [
      {
        label: 'Level two 3-1',
        children: [
          {
            label: 'Level three 3-1-1',
          },
        ],
      },
      {
        label: 'Level two 3-2',
        children: [
          {
            label: 'Level three 3-2-1',
          },
        ],
      },
    ],
  },
]
let RelationalData = ref([]);
let ac = ref([])

const dj = ()=>{
  ac.value = TotalPerson.value
}
let nodata = ref(true)
const trans_info = () => {
  let data = {
    sol_address: address.value,
  };
  get("/api/app/tree", data)
    .then((res) => {
      if(res.data.tree == null){
        nodata.value = false
      }
      else{
        nodata.value = true
      }
      TotalLdc.value = res.data.total_ldc;
      TotalPerson.value = res.data.total_person;
      RelationalData.value = [res.data.tree];
      ac.value = RelationalData.value
      if (res.data.total_ldc == null) {
        TotalLdc.value = 0;
      }
      if (res.data.total_person == null) {
        TotalPerson.value = 0;
      }
    })
    .catch((e) => {
      console.error(e);
    });
};
const defaultProps = {
  children: "children",
  label: "name",
};
const init = () => {
  let chartDom = document.getElementById("statistics");
  let myChart = echarts.init(chartDom);
  const option = {
    tooltip: {
      trigger: "item",
      triggerOn: "mousemove",
      formatter: function (params) {
        const data = params.data;
        const ldcValue = data.name ? data.name : "N/A"; // 获取 ldc 变量的值，如果不存在则显示 N/A
        return `${ldcValue}`;
      },
    },
    series: [
      {
        type: "tree",
        data: [RelationalData.value],
        top: "1%",
        left: "7%",
        bottom: "1%",
        right: "20%",
        symbolSize: 7,
        label: {
          position: "top",
          verticalAlign: "middle",
          align: "top",
          fontSize: 9,
        },
        leaves: {
          label: {
            position: "right",
            verticalAlign: "middle",
            align: "left",
          },
        },
        emphasis: {
          focus: "descendant",
        },
        expandAndCollapse: true,
        animationDuration: 550,
        animationDurationUpdate: 750,
      },
    ],
  };

  myChart.setOption(option);
};
</script>

<style lang="less" scoped>
.box {
  input {
    width: 400px;
    height: 32px;
    border: 1px solid #ccc;
    font-size: 14px;
    font-weight: 300;
    margin: 0 20px 0 6px;
    padding-left: 6px;
  }
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #fff;
    padding:60px;
    > div:nth-child(1) {
      font-size: 20px;
      // font-weight: 700;
    }
    > div:nth-child(2) {
      display: flex;
      font-size: 16px;
      margin-top: 20px;
      > div {
        margin-right: 20px;
        span {
          margin-left: 10px;
        }
      }
    }
  }
  #statistics {
    padding: 0 60px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    background-color: #fff;
  }
}
:deep(.el-button) {
  background-color: #409eff !important;
}
</style>