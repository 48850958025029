
<template>
  <!-- <div class="md:hero mx-auto p-4">
        <div class="md:hero-content flex flex-col"> -->
  <!-- <h1 class="text-center text-5xl md:pl-12 font-bold text-transparent bg-clip-text bg-gradient-to-tr from-[#9945FF] to-[#14F195]">
          Scaffold Lite <span class='text-sm font-normal align-top text-slate-700'>v{{pkg.version}}</span>
        </h1>
        <h4 class="md:w-full text-center text-slate-300 my-2">
          <p>Simply the fastest way to get started.</p>
          Next.js, tailwind, wallet, web3.js, and more.
        </h4>
        <div class="max-w-md mx-auto mockup-code bg-neutral p-6 my-2">
          <pre data-prefix=">">
            <code class="truncate">Start building on Solana  </code>
          </pre>
        </div> -->
  <!-- <div class="text-center">
                <request-airdrop></request-airdrop>
                <p class="text-white">SOL Balance: {{store.state.userSOLBalance}}</p>
            </div> -->
  <!-- </div>
    </div> -->
  <div class="box">
    <div>
      <app-bar
        class="block"
        @child-event="parentMethod"
        @child-event2="parentMethod2"
      ></app-bar>
      <div class="kuang">
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
        <img class="bubble" src="../assets/rw.png" alt="" />
      </div>
      <div class="content">
        <div>
          <div>
            <img src="../assets/bt.png" alt="" />
            <img src="../assets/xz.png" alt="" />
          </div>
          <img src="../assets/dt2.png" alt="" />
          <div>
            <p>Donation Address</p>
            <div>
              {{ formatString("4FbyCw1e5Qr2wf9ZV7mNTeDN5zHMs4r2YL2KMwed5rdX") }}
              <div>
                <img
                  @click="
                    copyTextToClipboard(
                      '4FbyCw1e5Qr2wf9ZV7mNTeDN5zHMs4r2YL2KMwed5rdX'
                    )
                  "
                  src="../assets/fz.png"
                  alt=""
                  style="cursor: pointer"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="swap_pc" v-if="windowWidth > 800">
          <div>
            <p>Pay</p>
            <div>
              <div>
                <img src="../assets/sol.png" alt="" />
                SOL
              </div>
              <input
                style="text-align: right; border: none; color: #000"
                type="number"
                placeholder="0.00"
                v-model="solQuantity"
                @input="solQuantityChange"
              />
            </div>
            <span>Balance: {{ balance }} SOL</span>
          </div>
          <!-- <img src="../assets/gq.png" alt="" /> -->
          <span class="flag-icon" :class="`flag-icon-${desiredCountry}`"></span>
          <div>
            <p>Receive</p>
            <div>
              <div>
                <img src="../assets/rw.png" alt="" />
                LDC
              </div>
              <input
                style="text-align: right; border: none; color: #000"
                type="number"
                placeholder="0.00"
                v-model="ldcQuantity"
                @input="ldcQuantityChange"
              />
            </div>
            <span>Balance: {{ ldcBalance }} LDC</span>
          </div>
          <!-- <p style="padding: 5px 0"></p> -->
          <div
            class="btns"
            v-if="InvitationAddress == null || InvitationAddress == ''"
          >
            <div v-if="!existenceata" @click="approve" v-loading="loading">
              Confirm
            </div>
            <div v-else @click="createata" v-loading="ataloading">
              establish ata
            </div>
          </div>
          <div class="btns" v-else>
            <div v-if="!existenceata" @click="Newpurchase" v-loading="loading">
              Confirm2
            </div>
            <div v-else @click="createata" v-loading="ataloading">
              establish ata
            </div>
          </div>
          <img class="tu1" src="../assets/jt.png" alt="" />
        </div>
        <div class="swap_m" v-if="windowWidth <= 800">
          <div>
            <p>Pay</p>
            <div>
              <div><img src="../assets/sol.png" alt="" /> SOL</div>
              <input
                style="text-align: right; border: none; color: #000"
                type="number"
                placeholder="0.00"
                v-model="solQuantity"
                @input="solQuantityChange"
              />
            </div>
            <div>Balance: {{ balance }} SOL</div>
          </div>
          <!-- <img src="../assets/gq.png" alt="" /> -->
          <span class="flag-icon" :class="`flag-icon-${desiredCountry}`"></span>
          <div>
            <p>Receive</p>
            <div>
              <div><img src="../assets/rw.png" alt="" /> LDC</div>
              <input
                style="text-align: right; border: none; color: #000"
                type="number"
                placeholder="0.00"
                v-model="ldcQuantity"
                @input="ldcQuantityChange"
              />
            </div>
            <div>Balance: {{ ldcBalance }} LDC</div>
          </div>
          <div
            class="btns"
            v-if="InvitationAddress == null || InvitationAddress == ''"
          >
            <div v-if="!existenceata" @click="approve" v-loading="loading">
              Confirm
            </div>
            <div v-else @click="createata" v-loading="ataloading">
              establish ata
            </div>
          </div>
          <div class="btns" v-else>
            <div v-if="!existenceata" @click="Newpurchase" v-loading="loading">
              Confirm2
            </div>
            <div v-else @click="createata" v-loading="ataloading">
              establish ata
            </div>
          </div>
          <img class="tu1" src="../assets/jt.png" alt="" />
        </div>
      </div>
      <div class="look-more">
        <img src="../assets/xx.png" alt="" />
        <img src="../assets/xx.png" alt="" />
        <img src="../assets/xx.png" alt="" />
      </div>
    </div>
    <div class="introduction">
      <img class="tu1" src="../assets/story.png" alt="" />
      <div ref="article1" id="article1">
        <h2>story <img src="../assets/tt.png" alt="" /></h2>
        <div>
          <p>
            In the year 2024, amidst the whirlwind of technological advancement,
            a clandestine world of extraordinary women thrives. Among them stand
            three remarkable individuals: Serena, Maya, and Olivia.
          </p>
          <p>
            Serena, a strategic mastermind, possesses a mind as sharp as a
            blade. Maya, a force of nature, inspires all who encounter her with
            her passion and unwavering determination. Olivia, with her keen
            intellect and technological prowess, serves as the backbone of their
            operations.
          </p>
          <p>
            Together, they form the core of a secret society dedicated to
            challenging the status quo and empowering women worldwide. Their
            mission is simple yet audacious: to establish the world's first
            female-led DAO organization and introduce LadyCoin, a revolutionary
            feminist currency.
          </p>
          <p>
            As they delve deeper into their mission, they encounter numerous
            obstacles and adversaries, each more daunting than the last. Forces
            opposed to their cause, fueled by fear and entrenched in tradition,
            seek to undermine their efforts at every turn.
          </p>
          <p>
            But Serena, Maya, and Olivia refuse to be deterred. With unwavering
            determination, they rally their allies and marshal their resources,
            fostering a sense of unity and camaraderie among women from all
            walks of life. Empowered by their shared vision, more allies join
            their cause, each bringing their unique skills and perspectives.
          </p>
          <p>
            Together, they confront the forces of oppression and inequality,
            forging a path toward a brighter future for women everywhere...
          </p>
        </div>
      </div>
      <img class="tu2" src="../assets/story.png" alt="" />
    </div>
    <div class="Economics" id="About">
      <h2 ref="article2" id="article2">Economics</h2>
      <div>
        <div>Total Supply 2 Trillion </div>
        <div>30% Donation</div>
        <div>
          <div>10% Bonus </div>
          <div>30% LP </div>
          <div>10% Liquidity Reward</div>
          <div>20% Fund</div>
        </div>
        <img class="tu1" src="../assets/x.png" alt="" />
        <img class="tu2" src="../assets/xxx.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script setup>
import RequestAirdrop from "../components/RequestAirdrop";
import store from "../vuex";
import pkg from "../../package.json";
import AppBar from "../components/AppBar.vue";
import {
  Connection,
  clusterApiUrl,
  LAMPORTS_PER_SOL,
  Program,
  PublicKey,
  Transaction,
  wallet,
  Keypair,
} from "@solana/web3.js";
import { useWallet } from "solana-wallets-vue";
import { AnchorProvider, BN, web3 } from "@project-serum/anchor";
import * as anchor from "@project-serum/anchor";
import {
  getOrCreateAssociatedTokenAccount,
  getAssociatedTokenAddress,
  createAssociatedTokenAccount,
  createAssociatedTokenAccountInstruction,
} from "@solana/spl-token";
import { ElMessage, ElNotification } from "element-plus";
import ido from "@/util/contracts/ioc.json";
import { toPublicKey } from "@metaplex-foundation/js";
import CryptoJS from "crypto-js";
import { JSEncrypt } from "jsencrypt";
import { get, post } from "../util/axios";
import BigNumber from 'bignumber.js';
import {
  onMounted,
  getCurrentInstance,
  reactive,
  ref,
  computed,
  onUnmounted,
  watch,
  onBeforeUnmount,
} from "vue";
let { proxy } = getCurrentInstance();
const { publicKey, connected, sendTransaction } = useWallet();
// const { connection } = connection();
let Address = ref(
  publicKey.value == null ? publicKey.value : publicKey.value.toString()
);
const searchParams = new URLSearchParams(window.location.hash.split("?")[1]);
let InvitationAddress = searchParams.get("address");
const currentURL = ref(window.location);
let windowWidth = ref(window.innerWidth);
const programId = ref("EjqRgR3ANdZkHwUXYJNci8UMuTpVfZWxkbAHERZvQV73");
let solQuantity = ref("");
let ldcQuantity = ref("");
// onBeforeUnmount(() => {
//   console.log('进来销毁了');
//     });
let desiredCountry = ref("us");
onMounted(() => {
  if (proxy.$route.query.typ == "Raids") {
    const articleElement = article1.value; // 替换为你要滑动到的文章的 ref
    if (articleElement) {
      articleElement.scrollIntoView({ behavior: "smooth" });
    }
  }
  if (proxy.$route.query.typ == "Ranking") {
    const articleElement = article2.value; // 替换为你要滑动到的文章的 ref
    if (articleElement) {
      articleElement.scrollIntoView({ behavior: "smooth" });
    }
  }
  let data = {};
  get("/api/app/ip", data)
    .then((res) => {
      if (res.data == "") return;
      if (res.data == "CN") return;
      if (res.data == "cn") return;
      desiredCountry.value = res.data.toLowerCase();
      // let url = res.data
      // window.location.href = url
      // desiredCountry.value = jsonData.find(item => item.countryEn === 'Egypt')
    })
    .catch((e) => {
      console.error(e);
    });
  if (publicKey.value == null) return;
  getBalance();
});
watch(
  () => publicKey.value,
  (newValue, oldValue) => {
    if (newValue != null) {
      Address.value = newValue;
      getBalance();
    }
  }
);
let article1 = ref(null);
const parentMethod = () => {
  // 执行父组件的逻辑
  const articleElement = article1.value; // 替换为你要滑动到的文章的 ref
  if (articleElement) {
    articleElement.scrollIntoView({ behavior: "smooth" });
  }
};
let article2 = ref(null);
const parentMethod2 = () => {
  // 执行父组件的逻辑
  const articleElement = article2.value; // 替换为你要滑动到的文章的 ref
  if (articleElement) {
    articleElement.scrollIntoView({ behavior: "smooth" });
  }
};
let contracts = {
  ldc: null,
  ata: null,
};
let ldcBalance = ref(0);
let provider = ref(null);
let balance = ref(0);
let existenceata = ref(false);
let ataloc = ref(null);
let Proportion = ref(0);
const getBalance = async () => {
  const wallet = useWallet();
  const connection = new Connection(clusterApiUrl("devnet"));

  const lamports = await connection.getBalance(publicKey.value, "confirmed");
  balance.value = lamports / LAMPORTS_PER_SOL;
  setBalance(balance.value);

  // contracts
  const walletPublicKey = new PublicKey(Address.value);

  // 代币Mint地址
  const mintPublicKey = new PublicKey(
    "3C93ps8VvmNSr5vTLoBs5uqmgNtSD7c9pTyy46bX4CwF"
  );
  // 计算ATA地址
  const ataAddress = await getAssociatedTokenAddress(
    mintPublicKey,
    walletPublicKey
  );
  ataloc.value = ataAddress;
  await connection
    // getAccountInfo
    .getParsedAccountInfo(ataAddress)
    .then(async (accoutInfo) => {
      if (accoutInfo.value == null) {
        existenceata.value = true;
        console.log("No ata");
      } else {
        existenceata.value = false;
        console.log("Ari ata");
      }
    });
  connection
    .getTokenAccountBalance(ataAddress)
    .then((balance) => {
      ldcBalance.value = balance.value.uiAmount;
    })
    .catch((error) => {
      console.error("获取余额时发生错误:", error);
    });
  provider.value = new anchor.AnchorProvider(connection, wallet, {
    commitment: "processed",
  });
  contracts.ldc = new anchor.Program(
    ido,
    "EjqRgR3ANdZkHwUXYJNci8UMuTpVfZWxkbAHERZvQV73",
    provider.value
  );
  const MintAddress = "3C93ps8VvmNSr5vTLoBs5uqmgNtSD7c9pTyy46bX4CwF";
  let DataPda = toPublicKey("6ZYcezd4swEQoeokahDKXw6Ap9gD5BUwQCMcP25UPj31");
  const [dataProgramPDA, dataProgramPDABump] = PublicKey.findProgramAddressSync(
    [new Buffer("data"), toPublicKey("9d8Uy7Zpcs7zhE1sd6zS3azQDbLT1XCphXSiCdueq48C").toBuffer()],
    toPublicKey(programId.value)
  );
  let ata = await contracts.ldc.account.data.fetch(dataProgramPDA);
  Proportion.value = ata.sol;
};
const setBalance = (balance) => {
  store.commit("setBalance", { balance });
};
let loading = ref(false);
const timer = ref(null);
const approve = async () => {
  if (Address.value == null)
    return ElNotification({
      title: "Warning",
      message: "Please link the wallet",
    });
  if (solQuantity.value <= 0)
    return ElNotification({
      title: "Warning",
      message: "The amount needs to be greater than 0",
    });
  if (balance.value < solQuantity.value)
    return ElNotification({
      title: "Warning",
      message: "Insufficient balance",
    });
  if (loading.value) return;
  loading.value = true;

  const MintAddress = "3C93ps8VvmNSr5vTLoBs5uqmgNtSD7c9pTyy46bX4CwF"; //币地址
  const [icoTokenStakeProgramPDA, icoTokenStakeProgramPDABump] =
    PublicKey.findProgramAddressSync(
      [toPublicKey(MintAddress).toBytes()],
      toPublicKey(programId.value)
    );
  const [dataProgramPDA, dataProgramPDABump] = PublicKey.findProgramAddressSync(
    [new Buffer("data"), toPublicKey("9d8Uy7Zpcs7zhE1sd6zS3azQDbLT1XCphXSiCdueq48C").toBuffer()],
    toPublicKey(programId.value)
  );
  const DataPda = "6ZYcezd4swEQoeokahDKXw6Ap9gD5BUwQCMcP25UPj31";
  const PdaForIco = "5iKKnR7eGTzEdgmfJMMz9793DYxMSRjM1w2d1N9PV9Vt";
  try {
    let txHash = await contracts.ldc.methods
      .buyWithSol(solQuantity.value)
      .accounts({
        data: toPublicKey(dataProgramPDA),
        icoAtaForUser: ataloc.value,
        icoAtaForIcoProgram: toPublicKey(icoTokenStakeProgramPDA),
        icoMint: toPublicKey(MintAddress),
        admin: toPublicKey("9d8Uy7Zpcs7zhE1sd6zS3azQDbLT1XCphXSiCdueq48C"),
        store: toPublicKey("DG2BcHbfeaRuLNT93dVDZbdJVwBcbheTV6jcY1HwtraX"),
        ataForSuperior: Keypair.generate().publicKey,
      })
      .rpc();

    clearInterval(timer.value);
    signParams();
    let data = {
      trans_hex: txHash,
      invitation_address: InvitationAddress,
      sol_address: Address.value,
      time_stamp: params.time_stamp,
      signature: signature1.value,
      ata: ataloc.value.toString(),
    };
    //
    post("/api/app/trans", data)
      .then(async (res) => {
        loading.value = false;
        if (res.code == 0) {
          updateData();
        } else {
          // ElMessage.error(res.msg);
          ElNotification({
            title: "Error",
            message: res.msg,
          });
        }
        // let url = res.data
        // window.location.href = url
      })
      .catch((e) => {
        console.error(e);
        timer.value = setInterval(() => {
          post("/api/app/trans", data)
            .then(async (res) => {
              loading.value = false;
              if (res.code == 0) {
                clearInterval(timer.value);
                updateData();
              }
            })
            .catch((e) => {
              console.error(e);
            });
        }, 8000);
      });
  } catch (error) {
    // 交易失败时的回调逻辑
    loading.value = false;
    ElNotification({
      title: "Error",
      message: "Network error, please check the network",
    });
    // ElMessage.error("Network error, please check the network");
    // 在这里执行其他操作或调用回调函数
  }
};
const updateData = async () => {
  const connection = new Connection(clusterApiUrl("devnet"));
  const lamports = await connection.getBalance(publicKey.value, "confirmed");
  balance.value = lamports / LAMPORTS_PER_SOL;
  setBalance(balance.value);
  ElNotification({
    title: "Success",
    message: "success",
  });
  localStorage.removeItem("txHash");
  // ldc余额
  const walletPublicKey = new PublicKey(Address.value);
  // 代币Mint地址
  const mintPublicKey = new PublicKey(
    "3C93ps8VvmNSr5vTLoBs5uqmgNtSD7c9pTyy46bX4CwF"
  );
  // 计算ATA地址
  const ataAddress = await getAssociatedTokenAddress(
    mintPublicKey,
    walletPublicKey
  );
  connection
    .getTokenAccountBalance(ataAddress)
    .then((balance) => {
      ldcBalance.value = balance.value.uiAmount;
      loading.value = false;
    })
    .catch((error) => {
      console.error("获取余额时发生错误:", error);
    });
  setTimeout(async () => {
    connection
      .getTokenAccountBalance(ataAddress)
      .then((balance) => {
        ldcBalance.value = balance.value.uiAmount;
        loading.value = false;
      })
      .catch((error) => {
        console.error(error);
      });
  }, 15000);
  setTimeout(async () => {
    connection
      .getTokenAccountBalance(ataAddress)
      .then((balance) => {
        ldcBalance.value = balance.value.uiAmount;
        loading.value = false;
      })
      .catch((error) => {
        console.error(error);
      });
  }, 25000);
  ldcQuantity.value = "";
  solQuantity.value = "";
};
function copyTextToClipboard(text) {
  const textarea = document.createElement("textarea");
  textarea.value = text;

  // Ensure the textarea is not visible
  textarea.style.position = "fixed";
  textarea.style.top = "-999999px";

  document.body.appendChild(textarea);
  textarea.select();

  // Execute the copy command
  document.execCommand("copy");

  // Clean up
  document.body.removeChild(textarea);
  ElNotification({
    title: "Success",
    message: "Copy successful.",
  });
}
//签名
let params = reactive({
  sol_address: Address.value,
  time_stamp: "",
  name: "lady-coin-20240508",
});
let privateKey = `
-----BEGIN RSA PRIVATE KEY-----
MIIEpgIBAAKCAQEA7vy08GHfMg8NcPeIXst1BMVi4ZSMmR7QlfN/o+5Ia2ikOEtX
D1XrVa8E0DD4F9i9/xFTUTmc/FRwjca49+r2RCMUpAiaiEixiXNh1dDYfp/bvAjV
4mxHICowTX7BT7ytdIzaLTx1JBHinWDwx85y7Ha+8CYISIm8BrcJOw/ocE4wcQb3
rPvN2sFuvr/bn525YnQnLUaQoF+7xNRY8M+/i4eAwPyUGKm3LI0waK4CCRI3DvaO
jTSoX47qj8hoWmzaGfCqHKcdFLFi1kIg3nh/Zulx8qtC1bhak6euNQFLJXBRiICp
5+IRyxZa3Si2Ro4hpX6OHDetpB6qlEj5/6Ud5wIDAQABAoIBAQClHyNhJy5QZwgR
cmXdoVOjzUGpvLp8HUdfI9OgD0YoOC+m2Z84cFPeasO3mO4Bg/aaYn0flN/7k9gE
jTZFzSkEIPzk7TDgUuGfp4IEenzq2Ea5H52wCMIH3j5rfNXR4voxmhQwxyHknxrl
RBJ46xKKgO0h/hUhEVFBjnupPC+MT5PoLU7zemAgkGR20szopj67AXZLQfjcuMv9
zm4hRfh/2/TwqdmQ6xgrmrNXb0u8c6VbtNV5yFBEuaJfnYc0MmWgaTNl//sDcHuc
pTNIEkfYnyuzIDRg8VjzXoE5PGCPaPJX/uxvxGq5H7FPjr4w6HtmFK99NF2FXwnS
hM3bhyb5AoGBAPjJZUgjaI/u06gXwAlUbxfTn2hHZg7f5wXHqS/tLh9J6aGs2SKy
V8NVs6rUdL5JOnTz5ZBTSiQgNqXs9Z1jGW36WfQk4osgOqxD5wAisNelqCNdbk+e
yHRqZ2KYaRW3jpvYGbbVFjxpAt5ROqxKSbNUvV9HUiUB3ln31ywVuAtTAoGBAPXq
kxCWiuqX/5ccMOPz872+y4L+gKNozDLyXLm980mk9Ah+SpZL2hoFBfQg4ZxJNK/x
xYFf51ms/D6AH0xVQEqnvrd3BECGIwi+74A/0mXHMmnh6CqBfhN3BPDPmQp66n26
7upxMHbuZsLo4sLzOTIM+p1PGBKa+kErA1wDx6SdAoGBAKXhGp/TxBccBZHxbACM
hJodZw8PdBP85DutWbhz6ym/jYBYEFsbEHtUfDwt9j0d9FUgffMSJcAannVZnpa3
CbCTmUC5Czf6YESV7cLgXosakBYsaK270CoW/CH4PzDUt8hfeR23zGCxH9F91aIk
0ik5Gh8aHcJBEbFpF9X0EUezAoGBALsAQg0fLUvvgeC7A0KUxAjKCZ/3QIMCsiUb
v/9Ovq6FzTyYLMfVrmiS4VAfoPBjirkwtOwIrbi3nqk4bJRzT4x1usVwr78RQZro
9t8jqS7UJzX1H8MS7XiHzS4B73LUvXQWro+Au0X1TBdzWNy+cAPrcrgk6T1ctGjR
+KAED9DRAoGBANcO1d5GoPLoVOtkdxAl2nfOwFVucVLZA7vrcPAvpq/i6JUGr5u0
7ctvMtqGZNHV5XH9VsclczuxzTERxoBfhoLfLgCHqkYgc6ppHqj44Xh3mdv2mF+7
gVAZNhJrYHz4eXZONNwIpdV9iWTYiJ3u/9/GirY90mK5U8J+s02VPfPn
-----END RSA PRIVATE KEY-----
`;
let signature1 = ref([]);
const signParams = () => {
  params.time_stamp = String(Date.parse(new Date()) / 1000);
  params.sol_address = Address.value;
  let keys = Object.keys(params).sort();
  let str = keys.map((k) => `${k}=${params[k]}`).join("&");
  var sign = new JSEncrypt();
  sign.setPrivateKey(privateKey);
  var signature = sign.sign(str, CryptoJS.SHA256, "sha256");
  signature1.value = signature.toString("base64");
};
let ataloading = ref(false);
//创建ata
const createata = async () => {
  if (ataloading.value) return;
  ataloading.value = true;
  const connection = new Connection(clusterApiUrl("devnet"));
  const walletPublicKey = new PublicKey(Address.value);

  // 代币Mint地址
  const mintPublicKey = new PublicKey(
    "3C93ps8VvmNSr5vTLoBs5uqmgNtSD7c9pTyy46bX4CwF"
  );

  // 计算ATA地址
  const ataAddress = await getAssociatedTokenAddress(
    mintPublicKey,
    walletPublicKey
  );
  let transaction = new Transaction().add(
    createAssociatedTokenAccountInstruction(
      walletPublicKey, // 付款人（通常是用户钱包）的公钥
      ataAddress, // ATA地址
      walletPublicKey, // 用户钱包公钥
      mintPublicKey // 代币Mint地址
    )
  );

  // const wallet = useWallet();
  // const { publicKey, connected, sendTransaction } = useWallet();

  // 签名交易
  // const signedTransaction = await sendTransaction(transaction, connection);
  // const signedTransaction = null
  sendTransaction(transaction, connection)
    .then(async (signedTransaction) => {
      await connection.confirmTransaction(signedTransaction);
      setTimeout(async () => {
        existenceata.value = false;
        ataloading.value = false;
      }, 4000);
    })
    .catch((error) => {
      // 交易失败时的回调逻辑
      ataloading.value = false;
      // 在这里执行其他操作或调用回调函数
      ElNotification({
        title: "Error",
        message: "Network error, please check the network",
      });
    });
};
function formatString(str) {
  const prefix = str.slice(0, 20);
  const suffix = str.slice(-5);
  return `${prefix}...${suffix}`;
}
const solQuantityChange = () => {
  ldcQuantity.value =Math.round(  new BigNumber(  new BigNumber(Number(solQuantity.value)).times(Number(Proportion.value))).times(100000));
};
const ldcQuantityChange = () => {
  solQuantity.value = Math.round(new BigNumber(new BigNumber(Number(ldcQuantity.value)).dividedBy(Number(Proportion.value))).dividedBy(100000));
};
let ifata = ref(false) 
const Newpurchase = async () => {
  if (Address.value == null)
    return ElNotification({
      title: "Warning",
      message: "Please link the wallet",
    });
  if (solQuantity.value <= 0)
    return ElNotification({
      title: "Warning",
      message: "The amount needs to be greater than 0",
      duration: 80000000000000000,
    });
  if (balance.value < solQuantity.value)
    return ElNotification({
      title: "Warning",
      message: "Insufficient balance",
    });
  const connection = new Connection(clusterApiUrl("devnet"));
  const [superior] = PublicKey.findProgramAddressSync(
    [Buffer.from("superior"), publicKey.value.toBuffer()],
    toPublicKey(programId.value)
  );
  const d = await connection.getAccountInfo(superior);
  if (JSON.stringify(d) != 'null'){
    approve()
  }
 
  if (loading.value) return;
  loading.value = true;
  const { blockhash } = await connection.getLatestBlockhash();

  // // 创建交易
  const transaction = new Transaction({ recentBlockhash: blockhash });
  let ins1 = await contracts.ldc.methods
    .setSuperior(toPublicKey(InvitationAddress))
    .accounts({
      superior: superior,
    })
    .instruction();
  const mintPublicKey = toPublicKey(
    "3C93ps8VvmNSr5vTLoBs5uqmgNtSD7c9pTyy46bX4CwF"
  );
  const walletPublicKey = toPublicKey(InvitationAddress);
  const walletPublicKey1 = toPublicKey(Address.value);
  // console.log('hahah',walletPublicKey,mintPublicKey);
  // // 计算ATA地址
  const ataAddress = await getAssociatedTokenAddress(
    mintPublicKey,
    walletPublicKey
  );
  
  await connection
    // getAccountInfo
    .getParsedAccountInfo(ataAddress)
    .then(async (accoutInfo) => {
      if (accoutInfo.value == null) {
        ifata.value = true;
        console.log("No ata");
      } else {
        ifata.value = false;
        console.log("Ari ata");
      }
    });
    let ins3 = {}
    if(ifata.value){
      ins3 = await createAssociatedTokenAccountInstruction(
      walletPublicKey1, // 付款人（通常是用户钱包）的公钥
      ataAddress, // ATA地址
      walletPublicKey, // 用户钱包公钥
      mintPublicKey // 代币Mint地址
    )
    }
  
  const DataPda = "6ZYcezd4swEQoeokahDKXw6Ap9gD5BUwQCMcP25UPj31";
  const PdaForIco = "5iKKnR7eGTzEdgmfJMMz9793DYxMSRjM1w2d1N9PV9Vt";
  const MintAddress = "3C93ps8VvmNSr5vTLoBs5uqmgNtSD7c9pTyy46bX4CwF"; //币地址

  const [icoTokenStakeProgramPDA, icoTokenStakeProgramPDABump] =
    PublicKey.findProgramAddressSync(
      [toPublicKey(MintAddress).toBytes()],
      toPublicKey(programId.value)
    );
  const [dataProgramPDA, dataProgramPDABump] = PublicKey.findProgramAddressSync(
    [new Buffer("data"), toPublicKey("9d8Uy7Zpcs7zhE1sd6zS3azQDbLT1XCphXSiCdueq48C").toBuffer()],
    toPublicKey(programId.value)
  );
  let ins2 = await contracts.ldc.methods
    .buyWithSol(solQuantity.value)
    .accounts({
      data: toPublicKey(dataProgramPDA),
      icoAtaForUser: ataloc.value,
      icoAtaForIcoProgram: toPublicKey(icoTokenStakeProgramPDA),
      icoMint: toPublicKey(MintAddress),
      admin: toPublicKey("9d8Uy7Zpcs7zhE1sd6zS3azQDbLT1XCphXSiCdueq48C"),
      store: toPublicKey("DG2BcHbfeaRuLNT93dVDZbdJVwBcbheTV6jcY1HwtraX"),
      ataForSuperior: ataAddress,
    })
    .instruction();
    if(ifata.value){
      transaction.add(ins3);
    }
  
  
  transaction.add(ins1);
  transaction.add(ins2);
  // console.log('ins',ins3,ins2);
  sendTransaction(transaction, connection)
    .then(async (Hash) => {
      signParams();
      let data = {
        trans_hex: Hash,
        invitation_address: InvitationAddress,
        sol_address: Address.value,
        time_stamp: params.time_stamp,
        signature: signature1.value,
        ata: ataloc.value.toString(),
      };
      //
      post("/api/app/trans", data)
        .then(async (res) => {
          loading.value = false;
          if (res.code == 0) {
            updateData();
          } else {
            ElNotification({
              title: "Error",
              message: res.msg,
            });
          }
          // let url = res.data
          // window.location.href = url
        })
        .catch((e) => {
          console.error(e);
          timer.value = setInterval(() => {
            post("/api/app/trans", data)
              .then(async (res) => {
                loading.value = false;
                if (res.code == 0) {
                  clearInterval(timer.value);
                  updateData();
                }
              })
              .catch((e) => {
                console.error(e);
              });
          }, 8000);
        });
    })
    .catch((error) => {
      ElNotification({
        title: "Error",
        message: "Network error, please check the network",
      });
    });
};
</script>
<style lang="less" scoped>
input:focus,
textarea:focus {
  outline: none;
}
input::placeholder {
  color: #000;
  background-color: #fff !important;
}
input {
  background-color: #fff !important;
}
.box {
  font-family: AbrilFatface;
  background-color: #f2ede1;
  > div:nth-child(1) {
    width: 100%;
    height: 100vh;
    @media (max-width: 800px) {
      // height: 600px;
    }
    position: relative;
    overflow: hidden;
    .jt {
      position: absolute;
      left: 50%;
      bottom: 20px;
      width: 60px;
      height: 60px;
    }
    .content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      padding: 0 8%;
      padding-top: 6%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 800px) {
        padding: 0 4%;
        padding-top: 100px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
      }
      .tu1 {
        width: 1.5rem !important;
        height: 0.85rem !important;
        flex-shrink: 0;
        position: absolute;
        left: -0.0125rem;
        top: -0.45rem;
      }
      > div:nth-child(1) {
        > div:nth-child(1) {
          display: flex;

          > img:nth-child(1) {
            width: 4.5625rem;
            height: 2.35rem;
          }
          > img:nth-child(2) {
            width: 2.1125rem;
            height: 1.6125rem;
            flex-shrink: 0;
            transform: translateY(-0.7rem);
          }
        }
        > img {
          width: 6.8rem;
          height: 4rem;
          margin-left: 3.75rem;
          transform: translateY(-1rem);
        }
        > div {
          @media (max-width: 800px) {
            margin-bottom: 20px;
            transform: translateY(-0.2rem);
          }
          p {
            color: #000;
            font-size: 0.25rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            transform: translateY(-1rem);
            @media (max-width: 800px) {
              transform: translateY(-0.4rem);
            }
          }
          > div {
            width: 9.875rem;
            height: 1rem;
            line-height: 1rem;
            flex-shrink: 0;
            background-color: #fff;
            color: #000;
            border-radius: 0.3rem;
            border: 0.0375rem solid #000;
            font-size: 0.375rem;
            font-weight: 400;
            display: flex;
            align-items: center;
            justify-content: space-between;
            // overflow: hidden;
            padding-left: 0.1875rem;
            box-shadow: 0.075rem 0.075rem 0px #ff7e00;
            // transform: translateY(-0.3rem);
            > div {
              background-color: #000;
              width: 1.25rem;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              border-bottom-right-radius: 0.1875rem;
              border-top-right-radius: 0.1875rem;
              img {
                width: 0.425rem;
                height: 0.425rem;
              }
            }
          }
        }
      }
      .swap_pc {
        width: 8.5rem;
        height: 7.775rem;
        flex-shrink: 0;
        border-radius: 0.3rem;
        border: 0.0375rem solid #000;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(37px);
        box-shadow: 0.075rem 0.075rem 0px #ff7e00;
        background-color: #ffe8d0;
        padding: 0 0.375rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        @media (max-width: 800px) {
          width: 100%;
          height: 270px;
        }
        :deep(.flag-icon-cn) {
          background-size: cover;
        }
        > div:nth-child(1) {
          background-color: #fff;
          width: 100%;
          margin: 0 auto;
          border-radius: 0.2rem;
          padding: 0.25rem;
          transform: translateY(0.375rem);

          p {
            color: #000;
            font-size: 0.25rem;
            font-weight: 600;
            margin-bottom: 0.375rem;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000;
            font-size: 0.3rem;
            font-weight: 600;
            margin-bottom: 0.125rem;
            @media (max-width: 800px) {
              margin-bottom: 0;
            }
            img {
              width: 0.55rem;
              height: 0.55rem;
              flex-shrink: 0;
              border-radius: 50%;
              margin-right: 0.125rem;
              @media (max-width: 800px) {
                margin-bottom: 0;
              }
            }
            span {
              color: #000;
              font-size: 0.3rem;
              font-weight: 600;
            }
          }
          > span {
            color: rgba(0, 0, 0, 0.4);
            font-size: 0.2rem;
            font-weight: 600;
          }
        }
        > span {
          width: 1rem;
          height: 1rem;
          object-fit: cover;
          z-index: 9;
          border-radius: 50%;
          box-shadow: 0px 0.0375rem 0.0875rem 0.0125rem #00000024;
        }
        :deep(.flag-icon) {
          background-size: cover;
          font-size: 60px;
        }
        > div:nth-child(3) {
          background-color: #fff;
          width: 100%;
          margin: 0 auto;
          border-radius: 0.2rem;
          padding: 0.25rem;
          transform: translateY(-0.375rem);
          p {
            color: #000;
            font-size: 0.25rem;
            font-weight: 600;
            margin-bottom: 0.375rem;
          }
          div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #000;
            font-size: 0.3rem;
            font-weight: 600;
            margin-bottom: 0.125rem;
            img {
              width: 0.55rem;
              height: 0.55rem;
              flex-shrink: 0;
              border-radius: 50%;
              margin-right: 0.125rem;
            }
            span {
              color: #000;
              font-size: 0.3rem;
              font-weight: 600;
            }
          }
          > span {
            color: rgba(0, 0, 0, 0.4);
            font-size: 0.2rem;
            font-weight: 600;
          }
        }
        > p {
          width: 100%;
          color: #000;
          font-size: 0.2rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-align: left;
          margin-bottom: 0.375rem;
          @media (max-width: 800px) {
            // margin-bottom:0;
            display: none;
          }
        }
        .btns {
          width: 100%;
          > div {
            width: 100%;
            height: 1rem;
            line-height: 1rem;
            text-align: center;
            color: #fff;
            border-radius: 0.2rem;
            background: #000;
            font-size: 0.3rem;
            font-weight: 600;
            cursor: pointer;
          }
        }
      }
      .swap_m {
        width: 96%;
        min-height: 100px;
        flex-shrink: 0;
        border-radius: 0.3rem;
        border: 0.0375rem solid #000;
        background: rgba(255, 255, 255, 0.8);
        backdrop-filter: blur(37px);
        box-shadow: 0.075rem 0.075rem 0px #ff7e00;
        background-color: #ffe8d0;
        padding: 0 0.375rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        > div:nth-child(1) {
          width: 100%;
          background-color: #fff;
          padding: 10px;
          border-radius: 6px;
          transform: translateY(10px);
          > p {
            color: #000;
            font-size: 12px;
            font-weight: 600;
          }
          > div:nth-child(2) {
            display: flex;
            justify-content: space-between;
            color: #000;
            font-family: "Abril Fatface";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 10px 0 6px 0;
            > div {
              display: flex;
              align-items: center;
              img {
                width: 20.322px;
                height: 19.787px;
                flex-shrink: 0;
                margin-right: 4px;
              }
            }
            input {
              width: 50%;
              text-align: right;
            }
          }
          > div:nth-child(3) {
            color: rgba(0, 0, 0, 0.4);
            font-family: "Abril Fatface";
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        > span {
          width: 30px;
          height: 30px;
          z-index: 99;
          border-radius: 50%;
          box-shadow: 0px 2px 5px 1px #00000024;
        }
        :deep(.flag-icon) {
          background-size: cover;
          font-size: 0.75rem;
        }
        > div:nth-child(3) {
          width: 100%;
          background-color: #fff;
          padding: 10px;
          border-radius: 6px;
          transform: translateY(-10px);
          > p {
            color: #000;
            font-size: 12px;
            font-weight: 600;
          }
          > div:nth-child(2) {
            display: flex;
            justify-content: space-between;
            color: #000;
            font-family: "Abril Fatface";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            padding: 10px 0 6px 0;
            > div {
              display: flex;
              align-items: center;
              img {
                width: 20.322px;
                height: 19.787px;
                flex-shrink: 0;
                margin-right: 4px;
              }
            }
            input {
              width: 50%;
              text-align: right;
            }
          }
          > div:nth-child(3) {
            color: rgba(0, 0, 0, 0.4);
            font-family: "Abril Fatface";
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .btns {
          width: 100%;
          height: 30px;
          line-height: 30px;
          text-align: center;
          background-color: #000;
          font-size: 16px;
          color: #fff;
          border-radius: 5px;
          margin-bottom: 10px;
        }
      }
    }
    .kuang {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .bubble {
      position: absolute;
      border-radius: 50%;
      animation: flutter 10s infinite;
      width: 0.75rem;
      height: 0.75rem;
    }
    @keyframes flutter {
      0% {
        transform: translateX(0);
        bottom: 100%;
      }
      50% {
        transform: translateX(100px);
      }

      100% {
        transform: translateX(0px);
        bottom: -100px;
      }
    }
    .bubble:nth-child(1) {
      left: -10%;
      width: 1.925rem;
      height: 1.925rem;
      animation-duration: 9s;
      animation-delay: 0.1s;
    }
    .bubble:nth-child(2) {
      left: 15%;
      width: 2rem;
      height: 2rem;
      animation-duration: 6s;
      animation-delay: 1.5s;
    }
    .bubble:nth-child(3) {
      left: 20%;
      width: 1.2rem;
      height: 1.2rem;
      animation-duration: 10s;
    }
    .bubble:nth-child(4) {
      left: 30%;
      width: 2rem;
      height: 2rem;
      animation-duration: 5.5s;
      animation-delay: 1.5s;
    }
    .bubble:nth-child(5) {
      left: 40%x;
      width: 1.625rem;
      height: 1.625rem;
      animation-duration: 12s;
    }
    .bubble:nth-child(6) {
      left: 50%;
      width: 0.5rem;
      height: 0.5rem;
      animation-duration: 6s;
      animation-delay: 1s;
    }
    .bubble:nth-child(7) {
      left: 60%;
      width: 0.9rem;
      height: 0.9rem;
      animation-duration: 8s;
      animation-delay: 1s;
    }
    .bubble:nth-child(8) {
      left: 65%;
      width: 0.95rem;
      height: 0.95rem;
      animation-duration: 15s;
    }
    .bubble:nth-child(9) {
      left: 80%;
      width: 1.6875rem;
      height: 1.6875rem;
      animation-duration: 9s;
      animation-delay: 0.5s;
    }
    .bubble:nth-child(10) {
      left: 100%;
      width: 0.5rem;
      height: 0.5rem;
      animation-duration: 12s;
    }

    .bubble:nth-child(11) {
      left: -20%;
      width: 0.375rem;
      height: 0.375rem;
      animation-duration: 9s;
      animation-delay: 0.1s;
    }
    .bubble:nth-child(12) {
      left: 30%;
      width: 0.5rem;
      height: 0.5rem;
      animation-duration: 6s;
      animation-delay: 1.5s;
    }
    .bubble:nth-child(3) {
      left: 10%;
      width: 0.45rem;
      height: 0.45rem;
      animation-duration: 10s;
    }
    .bubble:nth-child(14) {
      left: 90%;
      width: 0.875rem;
      height: 0.875rem;
      animation-duration: 5.5s;
      animation-delay: 1.5s;
    }
    .bubble:nth-child(15) {
      left: 70%x;
      width: 0.95rem;
      height: 0.95rem;
      animation-duration: 12s;
    }
    .bubble:nth-child(16) {
      left: 40%;
      width: 0.75rem;
      height: 0.75rem;
      animation-duration: 6s;
      animation-delay: 1s;
    }
    .bubble:nth-child(17) {
      left: 100%;
      width: 1.5rem;
      height: 1.5rem;
      animation-duration: 8s;
      animation-delay: 1s;
    }
    .bubble:nth-child(18) {
      left: 55%;
      width: 0.95rem;
      height: 0.95rem;
      animation-duration: 13s;
    }
    .bubble:nth-child(19) {
      left: 30%;
      width: 0.6875rem;
      height: 0.6875rem;
      animation-duration: 9s;
      animation-delay: 1s;
    }
    .bubble:nth-child(20) {
      left: 60%;
      width: 1.5rem;
      height: 1.5rem;
      animation-duration: 12s;
    }
  }
  .introduction {
    width: 100%;
    min-height: 100vh;
    padding: 0 8%;
    background: #f2ede1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 800px) {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: column;
      padding: 40px 0 0 0;
    }

    > img {
      width: 8rem;
      height: 9.75rem;
      flex-shrink: 0;
      @media (max-width: 800px) {
        margin-bottom: 60px;
        margin-top: 40px;
      }
    }
    @media (max-width: 800px) {
      .tu2 {
        display: block !important;
      }
      .tu1 {
        display: none !important;
      }
    }
    .tu1 {
      display: block;
    }
    .tu2 {
      display: none;
    }
    > div {
      width: 11.75rem;
      height: 9.75rem;
      border-radius: 0.3rem;
      border: 0.0375rem solid #000;
      padding: 0.375rem;
      @media (max-width: 800px) {
        width: 90%;
      }
      h2 {
        color: #000;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        text-shadow: 0.0375rem 0.0375rem 0px #ff7e00;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media (max-width: 800px) {
          font-size: 28px;
        }
        img {
          width: 1.75rem;
          height: 1.75rem;
          margin-left: 0.125rem;
        }
      }
      > div {
        overflow: auto;
        height: 7.125rem;
        p {
          color: #000;
          font-size: 0.4rem;
          font-weight: 400;
          margin-bottom: 0.25rem;
          font-family: AbrilFatface;
          @media (max-width: 800px) {
            font-size: 14px;
          }
        }
      }
    }
  }
  .Economics {
    width: 100%;
    height: 130vh;
    padding: 0 8%;
    background: #f2ede1;
    @media (max-width: 800px) {
      padding: 0 4%;
      height: 70%;
    }
    h2 {
      color: #000;
      text-align: center;
      font-size: 0.875rem;
      font-weight: 600;
      text-shadow: 0.0375rem 0.0375rem 0px #ff7e00;
      margin-bottom: 0.25rem;
    }
    > div {
      border-radius: 0.3rem;
      border: 0.0375rem solid #000;
      background: #ff7e002b;
      box-shadow: 0.075rem 0.075rem 0px #ff7e00;
      backdrop-filter: blur(0.4625rem);
      padding: 0.75rem;
      position: relative;
      @media (max-width: 800px) {
        padding: 10px;
      }
      .tu1 {
        width: 2.8375rem;
        height: 2.5rem;
        position: absolute;
        right: -1.25rem;
        top: -1.425rem;
        @media (max-width: 800px) {
          // display: none;
          width: 50px;
          height: 50px;
          right: -18px;
          top: -29px;
        }
      }
      .tu2 {
        width: 2.55rem;
        height: 2.9125rem;
        position: absolute;
        left: -1.375rem;
        bottom: -1.1875rem;
        @media (max-width: 800px) {
          // display: none;
          width: 50px;
          height: 50px;
          left: -20px;
          bottom: -20px;
        }
      }
      > div:nth-child(1) {
        border-radius: 0.3rem;
        border: 0.0375rem solid #ff7e00;
        background: #000;
        height: 2.25rem;
        line-height: 2.25rem;
        color: #fff;
        text-align: center;
        font-size: 0.625rem;
        font-weight: 600;
      }
      > div:nth-child(2) {
        border-radius: 0.3rem;
        border: 0.05rem solid #000;
        background: #fff;
        height: 2.25rem;
        line-height: 2.25rem;
        color: #000;
        text-align: center;
        font-size: 0.625rem;
        font-weight: 600;
        margin-top: 0.25rem;
      }
      > div:nth-child(3) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        > div {
          width: 49%;
          height: 2.25rem;
          border-radius: 0.3rem;
          border: 0.05rem solid #000;
          background: #fff;
          color: #000;
          text-align: center;
          font-size: 0.625rem;
          font-weight: 600;
          line-height: 2.25rem;
          margin-top: 0.25rem;
          @media (max-width: 800px) {
            width: 49%;
          }
        }
      }
    }
  }
}
.look-more {
  position: absolute;
  bottom: 0%;
  width: 105%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    display: none;
  }
}
.look-more img:first-child {
  animation-delay: -1s;
  -webkit-animation-delay: -1s;
}
.look-more img:nth-child(2) {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s;
}
.look-more img:last-child {
  animation-delay: 0s;
  -webkit-animation-delay: 0s;
}
.look-more img {
  stroke: #fff;
  fill: transparent;
  stroke-width: 2px;
  animation: downMove 2s infinite;
  -webkit-animation: downMove 2s infinite;
}
/*  down */
@keyframes downMove {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
.look-more img {
  width: 0.5rem;
  height: 0.5rem;
  display: inline-block;
}
:deep(.el-loading-spinner) {
  top: 30% !important;
  @media (max-width: 800px) {
    top: 60% !important;
  }

  @media (max-width: 800px) {
    .circular {
      width: 20px;
      height: 20px;
    }
  }
}
</style>