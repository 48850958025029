

<template>
    <div class="box">
        <app-bar class="block"></app-bar>
       <div class="content">
        <h2>raids</h2>
        <!-- https://ladycoin.b-cdn.net/img/story.528e55dc.png -->
        <img :src="`https://ladycoin.b-cdn.net/raids/raids_${tu}.png`" alt=""> 
        <div class="btns">
            <div @click="Attack">Generate Coin</div>
            <div @click="shareToTwitter">Attack</div>
        </div>
       </div>
    </div>
</template>
<script setup>
import { ref,getCurrentInstance } from 'vue';
import AppBar from '../components/AppBar.vue';
let { proxy } = getCurrentInstance();
// import ref from 'vue'
const getRandomInteger = ()=>{
  const min = 1;
  const max = 50;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
let tu = ref(9)
const Attack = ()=>{
    tu.value = getRandomInteger()
}
const shareToTwitter = ()=>{
  const text = encodeURIComponent("A quiet female revolution is gathering strength. lady's power is rising, calling on all female influencers to unite and together we can reshape the future of ladies around the world...");
  const url = encodeURIComponent(`https://ladycoin.io/?memeID=9`);
  const img = encodeURIComponent(`https://ladycoin.io/raids/raids_51.png`);
  const hashtags = "example,sharing";
  const twitterUrl = `https://twitter.com/intent/tweet?via=ladycoinsol&in_reply_to=1789735290908627140&text=${text}&url=${url}&media=${img}`;
  window.open(twitterUrl, "_blank");
}




</script>
<style lang="less" scoped>
.box{
    width: 100%;
    height: 160vh;
    background: url(../assets/bj.png) no-repeat;
    background-size: 100% 100%;
    font-family:AbrilFatface;
    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 8%;
        padding-top: 10%;
        @media (max-width: 800px) {
            padding: 0 4%;
            padding-top: 30%;
        }
        h2{
            color: #000;
        font-size:.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        text-shadow: .0375rem .0375rem 0px #ff7e00;
        margin-bottom: .25rem;
        }
        img{
            border-radius: 5px;
            height: 400px;
            @media (max-width: 800px) {
                height: 300px;
            }
        }
        .btns{
            display: flex;
            align-items:center;
            justify-content: space-between;
            margin-top: 40px;
            margin-bottom:40px;
            @media (max-width: 800px) {
                flex-direction: column;
                width: 100%;
            }
            >div:nth-child(1){
                margin-right: .25rem;
                @media (max-width: 800px) {
                width: 100%;
                margin: 0;
                margin-bottom: 15px;
                
            }
            }
            >div:nth-child(2){
                margin-left: .25rem;
                background-color: #000;
                color:#FFF;
                @media (max-width: 800px) {
                width: 100%;
                margin: 0;
            }
            }
            >div{
                display: flex;
                width:5rem;
                height: 1.25rem;
                padding: .175rem .425rem;
                justify-content: center;
                align-items: center;
                flex-shrink: 0;
                border-radius: .3rem;
                border: .0375rem solid #000;
                background: #FFF;
                color: #000;
                text-align: center;
                font-size: .4rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                cursor: pointer;
                @media (max-width: 800px) {
                    height:54px;
                    font-size: 16px;
                }
            }
        }
    }
}
</style>